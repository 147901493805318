import store from '@/store';
// TODO: Get from BE list of Networks
const networks = ['ERC20', 'TRC20', 'BEP20'];
export const allDepositMethodsDisabled = currency => {
  const {
    state: {
      user: { userDepositRules },
    },
  } = store;
  if (userDepositRules && currency) {
    const methodsByCurrency = userDepositRules[currency];
    if (!methodsByCurrency) return true;
    const allMethodsDisabled = Object.keys(methodsByCurrency)
      .map(methodName => {
        let method;
        if (methodsByCurrency[methodName] && methodsByCurrency[methodName]['P2P']) {
          if (Object.keys(methodsByCurrency[methodName]['P2P']).some(n => networks.includes(n))) {
            method = Object.keys(methodsByCurrency[methodName]['P2P'])
              .reduce((a, i) => {
                return [...a, methodsByCurrency[methodName]['P2P'][i]];
              }, [])
              .every(i => i.disable_description === 'DISABLED' || i.disable_description === 'RESET_PASSWORD_LOCK_IN_PROGRESS')
              ? { disable_description: 'DISABLED' }
              : { disable_description: 'NOT DISABLED' };
          } else {
            method = methodsByCurrency[methodName]['P2P'];
          }
        } else {
          method = methodsByCurrency[methodName];
        }
        const isMethodAvailable = method && method.disable_description;
        return isMethodAvailable;
      })
      .every(item => item === 'DISABLED' || item === 'RESET_PASSWORD_LOCK_IN_PROGRESS');
    return allMethodsDisabled;
  }
  return true;
};

export const allActiveDepositMethods = currency => {
  const {
    state: {
      user: { userDepositRules },
    },
  } = store;
  if (userDepositRules && currency) {
    const methodsByCurrency = userDepositRules[currency];
    if (!methodsByCurrency) return {};
    const availableMethodsByCurrency = Object.keys(methodsByCurrency).reduce((acc, curr) => {
      const method = methodsByCurrency[curr] && methodsByCurrency[curr]['P2P'] ? methodsByCurrency[curr]['P2P'] : methodsByCurrency[curr];
      if (method && method.disable_description !== 'DISABLED') {
        acc[curr] = method;
      }
      return acc;
    }, {});
    // TODO: need fix, this method Breinrock must to be response from BE
    if (availableMethodsByCurrency.CASHBOX) {
      if (['EUR', 'USD'].some(el=>el == currency)) {
        availableMethodsByCurrency.CASHBOX_BREINROCK = availableMethodsByCurrency.CASHBOX
      }
      delete availableMethodsByCurrency.CASHBOX;
    } 
    return availableMethodsByCurrency;
  }
  return {};
};
