import { allActiveDepositMethods } from '@/views/Application/Account/Exchange/Wallet/_helpers/depositRules';
export const walletFeesMethods = {
  methods: {
    enabledMethodsFilterHandler(key) {
      let result = allActiveDepositMethods(key);
      if (result.CASHBOX_BREINROCK) {
        delete result.CASHBOX_BREINROCK;
      }
      return result;
    },
    filterCurrency(currency) {
      if (currency === 'crypto') {
        return (
          Object.keys(this.getUserLimits.rules)
            .filter(currency => this.isCrypto(currency))
            .reduce((obj, key) => {
              obj[key] = this.getUserLimits.rules[key];
              return obj;
            }, {})
        );
      } else {
        return Object.keys(this.getUserLimits.rules)
          .filter(currency => !this.isCrypto(currency))
          .reduce((obj, key) => {
            obj[key] = this.getUserLimits.rules[key];
            return obj;
          }, {});
      }
    },
    // TODO change network exist method
    isNetworksExist(method) {
      if (method?.ERC20 || method?.BEP20) return true;
      return false;
    },
    enabledMethodHandler(method) {
      if (method && method.P2P) {
        if (!method.P2P.is_deposit_enabled && method.P2P.disable_description === 'DISABLED') {
          return false;
        } else {
          return true;
        }
      } else if (method && method && !method.is_deposit_enabled && method.disable_description === 'DISABLED') {
        return false;
      }
      return true;
    },
    payFeesHandler(payMethod, option) {
      const item = payMethod?.[option.mkey];
      if (option.network) {
        if (item?.P2P?.[option.network]) {
          if (item.P2P[option.network].percent_fee) {
            return item.P2P[option.network].static_fee
              ? `${item.P2P[option.network].percent_fee}% + ${item.P2P[option.network].static_fee} ${option.currency}`
              : `${item.P2P[option.network].percent_fee}%`;
          } else if (item.P2P[option.network].static_fee) {
            return item.P2P[option.network].percent_fee
              ? `${item.P2P[option.network].percent_fee}% + ${item.P2P[option.network].static_fee} ${option.currency}`
              : `${item.P2P[option.network].static_fee} ${option.currency}`;
          }
        } else if (item?.[option.network]) {
          if (item[option.network].percent_fee) {
            return item[option.network].static_fee
              ? `${item[option.network].percent_fee}% + ${item[option.network].static_fee} ${option.currency}`
              : `${item[option.network].percent_fee}%`;
          } else if (item[option.network].static_fee) {
            return item[option.network].percent_fee
              ? `${item[option.network].percent_fee}% + ${item[option.network].static_fee} ${option.currency}`
              : `${item[option.network].static_fee} ${option.currency}`;
          }
        }
        return 0 + ` ${option.currency}`;
      } else if (item?.P2P?.percent_fee) {
        return item.P2P.static_fee ? `${item.P2P.percent_fee}% + ${item.P2P.static_fee} ${option.currency}` : `${item.P2P.percent_fee}%`;
      } else if (item?.P2P?.static_fee) {
        return item.P2P.percent_fee
          ? `${item.P2P.percent_fee}% + ${item.P2P.static_fee} ${option.currency}`
          : `${item.P2P.static_fee} ${option.currency}`;
      } else if (item?.percent_fee) {
        return item.static_fee ? `${item.percent_fee}% + ${item.static_fee} ${option.currency}` : `${item.percent_fee}%`;
      } else if (item?.static_fee) {
        return item.percent_fee ? `${item.percent_fee}% + ${item.static_fee} ${option.currency}` : `${item.static_fee} ${option.currency}`;
      } else if (item?.P2P) {
        if (item.P2P.CARD) {
          let result = item.P2P.CARD.percent_fee ? `${item.P2P.CARD.percent_fee}%` : ''
          result = item.P2P.CARD.static_fee && result ? `${result} + ${item.P2P.CARD.static_fee} ${option.currency}` : item.P2P.CARD.static_fee ? `${item.P2P.CARD.static_fee} ${option.currency}` : result
          return result
        } else {
          return `${item.P2P.static_fee} ${option.currency}`;
        }
      } else if (item) {
        return `${item.static_fee} ${option.currency}`;
      } else {
        return '-';
      }
    },
    payLimitsHandler(payMethod, option) {
      if (payMethod) {
        const item = payMethod[option.mkey];

        if (option.network) {
          if (item?.[option.network]) {
            return item?.[option.network][option.mname];
          }
          return '';
        } else if (item && item.P2P) {
          return item.P2P?.CARD?.[option.mname] || item.P2P[option.mname];
        } else if (item && item[option.mname]) {
          return item[option.mname];
        }
        return '∞';
      }
      return '∞';
    },
  },
};
