<template>
  <div slot="content" v-if="getUserLimits.fees">
    <div
      class="walletLimitsTable w-full shadow-component-wrapper-root"
      v-for="(provider, key) in filterCurrency('crypto')"
      :key="key"
    >
      <div class="wlt-head" v-if="Object.keys(enabledMethodsFilterHandler(key)).length > 0">
        <div class="wlt-item">{{ key }}</div>
        <div class="wlt-item">deposit</div>
        <div class="wlt-item">withdrawal</div>
        <div class="wlt-item">fees</div>
      </div>
      <div class="wlt-wrapper" v-for="(method, methodKey) in enabledMethodsFilterHandler(key)" :key="methodKey">
        <div class="wlt-body" v-if="!isNetworksExist(method)">
          <div class="wlt-col">
            <div class="wlt-item">
              <div>
                <div class="flex items-center">
                  <SquareCoinIcon
                    :currency="key"
                  />
                  <div class="ml-[6px]">{{ key }}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="wlt-col">
            <div class="wlt-item">
            <span>
              min: <b>{{ payLimitsHandler(getUserLimits.limits.deposit[key], { mkey: methodKey, mname: 'min_amount' }) }}</b>
            </span>
              <span>
              max: <b>{{ payLimitsHandler(getUserLimits.limits.deposit[key], { mkey: methodKey, mname: 'max_amount' }) }}</b>
            </span>
            </div>
          </div>
          <div class="wlt-col">
            <div class="wlt-item">
            <span>
              min: <b>{{ payLimitsHandler(getUserLimits.limits.withdrawal[key], { mkey: methodKey, mname: 'min_amount' }) }}</b>
            </span>
              <span>
              max: <b>{{ payLimitsHandler(getUserLimits.limits.withdrawal[key], { mkey: methodKey, mname: 'max_amount' }) }}</b>
            </span>
            </div>
          </div>
          <div class="wlt-col">
            <div class="wlt-item">
            <span>
              Deposit: <b>{{ payFeesHandler(getUserLimits.fees.deposit[key], { mkey: methodKey, currency: key }) }}</b>
            </span>
              <span>
              Withdrawal: <b>{{ payFeesHandler(getUserLimits.fees.withdrawal[key], { mkey: methodKey, currency: key }) }}</b>
            </span>
            </div>
          </div>
        </div>
        <div v-else>
          <div class="wlt-body" v-for="(network, networkKey) in method" :key="networkKey">
            <div class="wlt-col">
              <div class="wlt-item">
                <div>
                  <div class="flex items-center">
                    <SquareCoinIcon :currency="key"/>
                    <div class="ml-[6px]">
                      {{ networkKey }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="wlt-col">
              <div class="wlt-item">
              <span>
                min:
                <b>{{
                    payLimitsHandler(getUserLimits.limits.deposit[key], { mkey: networkKey, mname: 'min_amount', network: networkKey })
                  }}</b>
              </span>
                <span>
                max:
                <b>{{
                    payLimitsHandler(getUserLimits.limits.deposit[key], { mkey: methodKey, mname: 'max_amount', network: networkKey })
                  }}</b>
              </span>
              </div>
            </div>
            <div class="wlt-col">
              <div class="wlt-item">
              <span>
                min:
                <b>{{
                    payLimitsHandler(getUserLimits.limits.withdrawal[key], { mkey: methodKey, mname: 'min_amount', network: networkKey })
                  }}</b>
              </span>
                <span>
                max:
                <b>{{
                    payLimitsHandler(getUserLimits.limits.withdrawal[key], { mkey: methodKey, mname: 'max_amount', network: networkKey })
                  }}</b>
              </span>
              </div>
            </div>
            <div class="wlt-col">
              <div class="wlt-item">
              <span>
                Deposit:
                <b>{{ payFeesHandler(getUserLimits.fees.deposit[key], { mkey: methodKey, currency: key, network: networkKey }) }}</b>
              </span>
                <span>
                Withdrawal:
                <b>{{ payFeesHandler(getUserLimits.fees.withdrawal[key], { mkey: methodKey, currency: key, network: networkKey }) }}</b>
              </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { walletFeesMethods } from '@/mixins/walletFeesMethods';
import { mapState } from 'vuex';
import SquareCoinIcon from '@/components/_common/SquareCoinIcon/SquareCoinIcon';
import { isCrypto } from '@/store/balance/_helpers/currencyTypeHelper';

export default {
  components: {
    SquareCoinIcon,
  },
  data: () => ({ status: false, isCrypto }),
  mixins: [walletFeesMethods],
  computed: {
    ...mapState('user', ['userLimits', 'userFees', '_userModuleLoaded', 'userDepositRules']),
    getUserLimits() {
      if (this.status) {
        return { limits: this.userLimits, fees: this.userFees, rules: this.userDepositRules };
      }
      return {};
    },
  },
  watch: {
    _userModuleLoaded: {
      handler(newVal) {
        if (newVal) this.status = newVal;
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/scss/_variables.scss';
@import '@/assets/scss/_colors.scss';
@import '../fees.scss';
</style>
